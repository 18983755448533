import React from "react";
import { graphql } from "gatsby";
import { FormattedMessage } from "react-intl";
import Img from "gatsby-image";
import LazyLoad from "react-lazyload";
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";
import RegionsSlider from "../components/regions-slider";

const RegionTemplate = ({ data, pageContext: context }) => {
  const { title, short_description, thumbnail, introduction } =
    data.contentfulRegion;

  const siteUrl = data.site.siteMetadata.siteUrl;
  const allPages = data.allContentfulPage.nodes;

  function getParentPage() {
    return allPages.find((page) => page.category === "Carnets de voyages");
  }

  return (
    <Layout>
      <Seo
        title={title}
        description={short_description}
        image={thumbnail && thumbnail.file.url}
      >
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": 1,
                  "name": "${getParentPage().title}",
                  "item": "${siteUrl}/${context.locale}/${
            getParentPage().slug
          }/"
                },
                {
                  "@type": "ListItem",
                  "position": 2,
                  "name": "${title}"
                }
              ]
            }
          `}
        </script>
      </Seo>
      <article>
        <section className="section section-white">
          <div
            className={`introduction${
              thumbnail ? " introduction-hero" : " introduction-clouds"
            }`}
          >
            <div className="introduction-container">
              <div className="introduction-inner">
                {thumbnail && (
                  <Img
                    className="introduction-image"
                    fluid={thumbnail.fluid}
                    loading="eager"
                    alt={title}
                  />
                )}
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-xl-8">
                      <p className="eyebrow">{getParentPage().title}</p>
                      <h1>{short_description}</h1>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section section-gradient overflow-hidden">
          <div className="container">
            <div className="row justify-content-center mb-16 mb-md-30">
              <div className="col-lg-8">
                {renderRichText(introduction, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => (
                      <p className="lead">{children}</p>
                    ),
                  },
                })}
              </div>
            </div>
            {data.allContentfulHotel.nodes.map((node, i) => {
              return (
                <div
                  className={
                    node.guide.thumbnail ? "columns columns-reverse" : "columns"
                  }
                  key={i}
                >
                  {node.guide.thumbnail && (
                    <div className="columns-item columns-item-image">
                      <Img
                        fluid={{
                          ...node.guide.thumbnail.fluid,
                          aspectRatio: 460 / 610,
                        }}
                        alt={title}
                      />
                    </div>
                  )}
                  <div
                    className={`columns-item${
                      node.guide.thumbnail
                        ? " columns-item-text"
                        : " columns-item-center"
                    }`}
                  >
                    <h2>{node.guide.main_title}</h2>
                    {renderRichText(node.guide.content)}
                    <p className="mt-md-6">
                      <a
                        href={`https://www.ace-hotel-${node.slug}.com/${
                          context.locale
                        }/${getParentPage().slug}/`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FormattedMessage id="more" />
                      </a>
                    </p>
                    <h3 className="font-weight-normal">
                      <FormattedMessage id="guide.stopover" />
                    </h3>
                    <div className="d-flex mt-6">
                      {node.spirit.logo && (
                        <Img
                          className="flex-shrink-0"
                          fluid={node.spirit.logo.fluid}
                          alt={node.spirit.title}
                          style={{ width: "80px", height: "80px" }}
                        />
                      )}
                      <div className="ml-6">
                        <p className="mt-0 mb-2">
                          <strong>{`ACE Hôtel${
                            data.contentfulHotel.spirit &&
                            data.contentfulHotel.spirit.slug === "travel"
                              ? " Travel"
                              : ""
                          } ${node.title}`}</strong>
                        </p>
                        <p className="mt-2">
                          <a
                            href={`https://www.ace-hotel-${node.slug}.com/${context.locale}/`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FormattedMessage id="visit" />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <aside className="mt-16 mt-md-30">
              <h2>
                <FormattedMessage id="guide.destination" />
              </h2>
              <div className="row mt-8 mt-md-10">
                <div className="col-8 col-sm-10 col-lg-12 position-static">
                  <LazyLoad>
                    <RegionsSlider />
                  </LazyLoad>
                </div>
              </div>
            </aside>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default RegionTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulRegion(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      short_description
      introduction {
        raw
      }
      thumbnail {
        file {
          url
        }
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      node_locale
    }
    allContentfulHotel(
      filter: { node_locale: { eq: $locale }, region: { slug: { eq: $slug } } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        guide {
          main_title
          content {
            raw
          }
          thumbnail {
            fluid(maxHeight: 610, quality: 80) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        title
        slug
        spirit {
          logo {
            fluid(maxWidth: 100, quality: 100) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          title
          slug
        }
        node_locale
      }
    }
    allContentfulRegion(
      filter: { node_locale: { eq: $locale }, slug: { ne: $slug } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        slug
        thumbnail {
          fluid(maxHeight: 320, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
